<template>
    <div>

  <div class="article-detail__domains white">
    <h2>{{title}}</h2>
    <div v-scrollanimation class="Article-detail__description">
      <p class="Article-detail__description--text">{{ content }}</p>
    </div>
  </div>

   <div v-scrollanimation v-bind:style="{'transition-delay':'0.2s'}" class="Article-detail__description__Image">
       
          <img
            class="Article-detail__description__inlineImages--size"
            v-if="image"
           v-lazy="api_url + image"
            alt="nothing"
          />
      
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  props: {
     content: {
      type: String,
    },
    title:{
        type: String,
    },
    image:{
        type:String,
    }
  },
};
</script>

<style scoped>
.before-enter{
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.7s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>