<template>
  <div class="About" v-if="this.aboutMePage.ProfessionalExperience">


<Head :content="this.aboutMePage"></Head>
<div>
  <div class="article-detail__width center article-detail__container">
  <HowIWork :content="this.aboutMePage.AboutMeText"  :title="'About Me'" class="margin-bottom--XS"/>
</div>
  <div class="article-detail__width center article-detail__container">
  <ImageSlider v-if="this.aboutMePage.AboutMeGalery"  :images="this.aboutMePage.AboutMeGalery" class="margin-bottom--XL"></imageSlider>
</div>

<div class="article-detail__width center article-detail__container">
  <Contact :content="this.aboutMePage.Contact.Contact" class="margin-bottom--XL"  ></Contact>
</div>

<div class="article-detail__width center article-detail__container">
  <HowIWork :content="this.aboutMePage.HowIWork" :image="this.aboutMePage.HowIWorkImage.url" :title="'How I Work'" class="margin-bottom--XL"/>
 
</div>

<div class="article-detail__width center article-detail__container">
 <Timeline  v-if="this.aboutMePage.ProfessionalExperience" :content="this.aboutMePage.ProfessionalExperience || []" :title="'Experience'"/>
 </div>
 
<div class="article-detail__width center article-detail__container">
   <Timeline v-if="this.aboutMePage.Education.items" :content="this.aboutMePage.Education" :title="'Education'"/>
 </div>

<div class="article-detail__width center article-detail__container">
   <Awards v-if="this.aboutMePage.Awards" :test="this.aboutMePage.Awards" :title="'Awards'" :batches="this.aboutMePage.AwardsBatches" class="margin-bottom--XL"/>
 </div>




</div>



  </div>
</template>

<script>
import gql from "graphql-tag";
import Head from "../components/Head.vue";
import HowIWork from "../components/HowIWork.vue";
import Timeline from "../components/Timeline.vue";
import Awards from "../components/Awards.vue";
import ImageSlider from "../components/ImageSlider.vue"
import Contact from "../components/Contact.vue"

export default {
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      aboutMePage: {},
      Head
    };
  },
  components: {
    Head,
    HowIWork,
    Timeline,
    Awards,
    ImageSlider,
    Contact
  },

  apollo: {
    aboutMePage: gql`
      query aboutMePage {
        aboutMePage {
        aboutMeIntro
        aboutMeIntro2
          AboutMeImage
          {url}
          HowIWork
          HowIWorkImage{url}
          ProfessionalExperience
          Education
          Awards
          AwardsBatches{url}
          AboutMeText
          AboutMeGalery{url}
          Contact
        }
      }
    `,
  }
};
</script>

<style scoped>



</style>



