<template>
  <div v-scrollanimation  v-bind:class="{'before-enter': isOpen==false}" v-bind:style="[isOpen == false ? {'pointer-events':'none'}:{'pointer-events':'auto'}]" class="GDPR-message">
    <div class="GDPR-message__container">
      <div class="GDPR-message__container__message white">
        <i  class="bx bx-cookie bx-md " style="color:white"></i>
        <p class="white">
          I use Google Analytics to improve my website, find errors and analyze my traffic. Have fun!
        </p>
      </div>
      <div class="GDPR-message__container__buttons">
              <button class="GDPR-button GDPR-button-secondary" @click="deny()"><span>deny</span></button>
        <button class="GDPR-button GDPR-button-primary" @click="accept()"><span>accept</span></button>
    
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isOpen: false,
      accept: function() {
        localStorage.setItem("GDPR:accepted", true);
        this.isOpen = false;
      },
      deny: function() {
        localStorage.setItem("GDPR:accepted", false);
        this.isOpen = false;
      },
    };
  },
  mehtods: {
    getGDPR() {
      console.log(localStorage.getItem("GDPR:accepted", true));
      return localStorage.getItem("GDPR:accepted", true);
    },
  },
  created() {
    if (!this.getGDPR === true) {
      this.isOpen = true;
    }
  },
};
</script>

<style scoped>


.before-enter{
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.7s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
 
}

.GDPR-message {
  z-index: 9999999999;
  position: fixed;
  width: 100%;
  bottom: 50px;
}

.GDPR-message__container {
  display: flex;
  flex-direction: column;
  background: #0046ff;
  margin: auto;
  padding:20px;
  border-radius: 5px;
  width: 40%;
  box-shadow: 0 5px 10px 0 rgba(77, 7, 7, 0.15), 0 5px 10px 0 rgba(0, 0, 0, 0.1),
        inset 0 -1px 2.5px 0 rgba(255, 255, 255, 0.3);
}

.GDPR-message__container__message{
color: white;
  display: flex;
  align-items: center;
  margin:auto;
  --gap: 30px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
  margin-bottom: 10px;
}

.GDPR-message__container__message > * {
      margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
}

.GDPR-message__container__buttons{
    display: flex;
    justify-content: flex-end;
     align-items: center;
     --gap: 10px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
}

.GDPR-message__container__buttons > * {
      margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
}

.GDPR-button{
    display: inline-block;
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 3px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.GDPR-button span {
    margin-left: 30px;
    margin-right: 30px;
}

.GDPR-button-primary{
    background-color: white;
}

.GDPR-button-secondary{
    background-color :#2259ee;
    color: white;
}

@media only screen and (max-width: 600px) {
.GDPR-message__container__message{
flex-direction: column;
}

.GDPR-message__container {
  width: 70%;
}
.GDPR-button{
      padding-top: 10px;
    padding-bottom: 10px;
}

}

@media only screen and (min-width: 600px) and (max-width: 1200px) and (orientation: portrait) {

    .GDPR-message__container__message{
flex-direction: row;
}

.GDPR-message__container {
  width: 80%;
}
.GDPR-button{
      padding-top: 10px;
    padding-bottom: 10px;
}

}


@media only screen and (min-width: 600px) and (max-width: 1200px) and (orientation: landscape) {

    .GDPR-message__container__message{
flex-direction: row;
}

.GDPR-message__container {
  width: 60%;
}
.GDPR-button{
      padding-top: 10px;
    padding-bottom: 10px;
}
    
}
@media only screen and (min-width: 1200px)and (max-width: 1900px) and  (hover: none) {

    .GDPR-button{
      padding-top: 10px;
    padding-bottom: 10px;
}
}
@media only screen and (min-width: 1900px) {}

</style>
