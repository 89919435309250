<template>
 <div  class="About--start-view" >
      <div class="About--start-view__container global--width">
      
        <div class="About--start-view__right">
          <img 
            v-if="content.AboutMeImage"
            class="About--start-view__right__image"
           v-lazy="api_url + content.AboutMeImage.url"
            alt=""
          />
        </div>
       <ul  v-scrollanimation class="About--start-view__left" >

            <li v-scrollanimation class="About--start-view__left white" v-html="content.aboutMeIntro" />
            <br>
          <li v-scrollanimation class="About--start-view__left white" v-bind:style="{'color':'white','transition-delay': '1s'}" v-html="content.aboutMeIntro2" />
       </ul>
      </div>
    </div>
</template>
<script>
export default {
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  props: {
     content: {
      type: Object,
    }
  },
};
</script>

<style scoped>

.before-enter{
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.7s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}


.About--start-view {
    max-width: 100%;
    height: 100vh;
  }
  
  .About--start-view__container {
    display: flex;
    flex-direction: row;
    margin: auto;
    height: 100%;
  }
  
.About--start-view__left{
  list-style: none;
}

  
  @media only screen and (max-width: 1200px) {
    .About--start-view__left {
      flex: 60%;
      max-width: auto;
      height: auto;
      align-self: center;
    }
  
    .About--start-view__right {
      display: none;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .About--start-view__left {
      flex: 40%;
      max-width: auto;
      height: auto;
      align-self: center;
    }
  
    .About--start-view__right {
      flex: 50%;
      max-width: auto;
      height: auto;
      align-self: flex-end;
    }
  }
  
  
  .About--start-view__right__image {
    width: 100%;
    margin-top: auto;
  }
  
</style>