import Vue from "vue";
import VueApollo from "vue-apollo";
import VueRouter from "vue-router";

import apolloClient from "./vue-apollo";

import App from "./App.vue";

import ScrollAnimation from "./directives/scrollanimation";

// import VueAnalytics from "vue-analytics";
import VueGtag from "vue-gtag";

import VueLazyload from 'vue-lazyload'

//  const Work = () => import(/* webpackChunkName: "group-foo" */ './containers/Work.vue')

Vue.use(VueLazyload)

// or with options


Vue.use(VueApollo);
Vue.use(VueRouter);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'src/assets/error.png',
  loading: 'src/assets/loading.gif',
  attempt: 3,
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  listenEvents: [ 'scroll' ]
})
Vue.config.productionTip = false;

Vue.directive("scrollanimation", ScrollAnimation);

 const getGDPR = localStorage.getItem("GDPR:accepted");

if (typeof getGDPR != "undefined" && getGDPR === "true") {
  Vue.use(VueGtag, {
    config: { id: "G-31XNDYNLSY" },
    enabled: true
  });
} else if (typeof getGDPR != "undefined" && getGDPR === "false") {
  Vue.use(VueGtag, {
    config: { id: "G-31XNDYNLSY" },
    enabled: false
  });
}

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name:"work",
      components: require("./containers/Work.vue"),
    },
    {
      path: "/article/:id",
      components: require("./containers/Article.vue"),
    },
    {
      path: "/impressions/:id",
      components: require("./containers/BlogDetail.vue"),
    },
    {
      path: "/testside",
      components: require("./containers/TestSide.vue"),
    },
    {
      path: "/impressions",
      components: require("./containers/microProjects.vue"),
    },
    {
      path: "/about",
      components: require("./containers/about.vue"),
    },
    {
      path: "/imprint",
      components: require("./containers/imprint.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount("#app");
