<template>
  <div v-if="footer">
     <VueMarkdownLite class="MarkdownWrapper-Content margin-bottom--M margin-top--XL"
        
        >{{footer.Imprint}}</VueMarkdownLite>
  </div>
</template>

<script>

import VueMarkdownLite from '@earthtone/vue-markdown-lite'

import gql from "graphql-tag";

export default {
  data() {
    return {
    }
  },
  props : {
      content:String
  },
  components: {
VueMarkdownLite
  // MoreInformation,
  },

    apollo: {
    footer: gql`
      query footer {
        footer {
          
          Imprint
        }
      }
    `,
  },



};
</script>

<style scoped>

.MarkdownWrapper-Content{
    margin-left: auto;
     margin-right: auto;
    width: 70%;
}


</style>
